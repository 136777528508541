.logo {
    height: 32px;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;
}

.citrin-text {
    font-weight: bold;
    color: #F5D91D;
    background: rgba(0, 0, 0, 0);
}

.insights-text {
    font-weight: bold;
    color: #fff;
    margin: 5px;
    background: rgba(0, 0, 0, 0);
}
 
.site-layout .site-layout-background {
    background: #fff;
}

[data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
}