.main {
    position: absolute;
    TOP: 60px;
    width: calc(100% - 260px);
    left: 260px;
    min-height: calc(100% - 60px);
    background: #f5f5f5;
}

/* card*/
.cards {
    width: 100%;
    padding: 35px 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.cards .card {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.08);
}

.card {
    transition: transform 0.4s ease-in-out;
}

.card:hover {
    transform: translateY(-8px);
}

.card.clicked {
    transform: translateY(-5px);
}

.card-number {
    font-size: 35px;
    font-weight: 500;
    color: #303fe4;
}

.card-name {
    font-size: 15px;
    color: #888;
    font-size: 600;
}

.icon-box i {
    font-size: 20px;
    color: #303fe4;
}

/*charts*/

.charts {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
    width: 90%;
    padding: 20px;
    padding-top: 0;
}

.chart {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 7px rgba(0, 0, 0, 0.08);
    width: 100%;
}